.root {
  position : relative;

  width : 100%;
  height : 100%;
  background-color: transparent;
  border-radius: 12px;

  --size : 150px;
  
  .loaderBox {
    position: absolute;

    left: calc(50% - (var(--size) / 2));
    top : calc(50% - (var(--size) / 2));
  
    width : var(--size);
    height : var(--size);
    background-color: var(--kezios-color-light);
    border: var(--kezios-color-extra-light) 4px solid;
    border-radius: 24px;
  }
  
  .loaderLogo {
    position: absolute;

    --logo-size : calc(var(--size) * 0.6);
    --logo-position : calc(50% - (var(--logo-size) / 2));

    left: var(--logo-position);
    top : var(--logo-position);
  

    width : var(--logo-size);
    height : var(--logo-size);
  }
}